import Regexes from '@/Regexes';
import {z, type UnknownKeysParam, type ZodRawShape, type ZodTypeAny} from 'zod';

export function transformUndefinedToNull(val) {
  return val === undefined ? null : val;
}

const whitespaceRegex = /\s+/g;
export function transformMergeWhitespace(val: string) {
  return val.replace(whitespaceRegex, ' ');
}

const validateUrlPath = (path: string) => {
  try {
    // Use the WHATWG URL API to validate the pathname part of a URL
    const parsedUrl = new URL(`http://localhost${path}`);
    return parsedUrl.pathname === path;
  } catch {
    return false;
  }
};

export function zUrlPath() {
  return z.string().refine((path) => validateUrlPath(path), {
    message: 'Invalid URL path',
  });
}

export const noWhitespaceUnlessLiteralSpace = z
  .string()
  .regex(Regexes.NoWhitespaceUnlessLiteralSpace);

export const transformWhitespaceIfNoLiteralSpace = z
  .string()
  .trim()
  .transform(transformMergeWhitespace);

export const href = z.string().url('Invalid URL');

export const url = href;

export const money = z.number().min(0, 'Amount must be positive');

export const list = z.object({
  searchQuery: z.string().optional(),
  isDescending: z.boolean().optional(),
  limit: z.number().max(50).optional(),
});

export const stripeId = z.string();
